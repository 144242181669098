<script>

export default {
  components: {
    
  },
  props: [
      'dataTestimonial'
  ],
  data() {
    return {
      options: {


      }
    }
  }
};
</script>

<style>
.testimonial-quote {
  width: 100%;
  min-width: 70vw;
}

.quote-detail {
  margin: 0px 10px 0px 10px !important;
    color: var(--grey);
    width: 100%;
    text-align: center;
}

.testimonial-box {
  
}

.quote-icon {
    height: 20px;
    width: 20px;
    margin: 0px 0px 0px auto;
    padding: 10px;
    background-color: var(--light-grey);
    -webkit-mask-size: contain;
    mask-size: contain;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    mask-image: url("../assets/icons/quote.svg");
    transform: scale(0.75,-1);
    align-self: flex-start;
}

.content-panel.testimonial-quote {
  margin: 10px 10px;
}

.testimonial-container {
  align-items: center;
  display: flex !important;
}

.testimonial-info {
  width: 40%;
}

@media screen and (max-width: 1165px) {
  .testimonial-quote {
    min-width: 65vw;
  }
}
@media screen and (max-width: 990px) {
  .testimonial-quote {
    min-width: 60vw;
  }
}
@media screen and (max-width: 990px) {
  .testimonial-quote {
    min-width: 53vw;
  }
}
@media screen and (max-width: 750px) {
  .testimonial-quote {
    min-width: 85vw;
  }
}

</style>

<template>
  <div>
    <div class="box-container testimonial-container">
      <div class="testimonial-info">
        <div class="detail"><strong>Name</strong>: <a :href="`/contacts/people/${dataTestimonial.person}`"><strong>{{ dataTestimonial.first_name }} {{ dataTestimonial.last_name }}</strong></a></div>
        <div class="detail"><strong>Project</strong>: <a :href="`/projects/${dataTestimonial.project}`"><strong>{{ dataTestimonial.project_name }}</strong></a></div>
        <div class="detail"><strong>Organization</strong>: <a :href="`/contacts/organizations/${dataTestimonial.organization}`"><strong>{{ dataTestimonial.organization_name }}</strong></a></div>
        <div class="detail"><strong>Type</strong>: Quote</div>
        <div class="detail">{{ dataTestimonial.created_date | formatDate }}</div>
      </div>
      <div class="detail quote-detail">{{ dataTestimonial.testimonial_text}}</div>
      <div class="quote-icon"></div>
    </div>
  </div>

</template>