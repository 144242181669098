<script>
import { GChart } from "vue-google-charts";

export default {
  components: {
    GChart
  },
  props: [
      'chartData'
  ],
  data() {
    return {
      options: {
        width: '100%',
        height: 200,
        slices: [
          {color: '#A15A95', textStyle: {color: '#FFFFFF'}},
          {color: '#17BEBB', textStyle: {color: '#FFFFFF'}},
          {color: '#F9C815', textStyle: {color: '#000000'}}
        ],
        pieHole: 0.3,
        backgroundColor: { fill:'transparent' },
        pieSliceText: 'value',

      }
    }
  }
};
</script>

<template>
  <div class="data-visualization center">
      <GChart
        type="PieChart"
        :options="options"
        :data="chartData"
        :resizeDebounce="400"
      />
  </div>

</template>