<template>
  <div class="status-bar" :style="barStatuses">
    <div class="ont-status" :title="'On Track: ' + statusCount.ONT"></div>
    <div class="oft-status" :title="'Off Track: ' + statusCount.OFT"></div>
    <div class="rsk-status" :title="'At Risk: ' + statusCount.RSK"></div>
    <div class="comp-status" :title="'Completed: ' + statusCount.COMP"></div>
    <div class="cncld-status" :title="'Cancelled: ' + statusCount.CNCLD"></div>
  </div>
</template>

<style>
.status-bar {
  width: 75%;
  height: 14px;
  margin-left: 35px;
  display: flex;
}

.ont-status {
  width: var(--ont-status);
  height: 100%;
  background-color: var(--green);
  float: left;
}

.oft-status {
  width: var(--oft-status);
  height: 100%;
  background-color: var(--red);
  float: left;
}

.rsk-status {
  width: var(--rsk-status);
  height: 100%;
  background-color: var(--yellow);
  float: left;
}
.comp-status {
  width: var(--comp-status);
  height: 100%;
  background-color: #3f3f3f;
  float: left;
}

.cncld-status {
  width: var(--cncld-status);
  height: 100%;
  background-color: #b8b7b7;
  float: left;
}
</style>

<script>
// import Tooltip from "../components/Tooltip";
export default {
  props: {
    statuses: {
      type: Array,
      required: true,
    },
  },

  components: {
    //   Tooltip
  },

  computed: {
    statusCount: function() {
      const counts = {};
      this.statuses?.map((s) => {
        counts[s[0]] = (counts[s[0]] || 0) + 1;
      });
      return counts;
    },

    barStatuses: function() {
      const total = this.statuses?.length;
      return {
        "--rsk-status": `${(100 * (this.statusCount.RSK / total)).toFixed(1)}%`,
        "--ont-status": `${(100 * (this.statusCount.ONT / total)).toFixed(1)}%`,
        "--oft-status": `${(100 * (this.statusCount.OFT / total)).toFixed(1)}%`,
        "--comp-status": `${(100 * (this.statusCount.COMP / total)).toFixed(
          1
        )}%`,
        "--cncld-status": `${(100 * (this.statusCount.CNCLD / total)).toFixed(
          1
        )}%`,
      };
    },
  },
};
</script>
