<template>
  <div>
    <span
      v-if="updates.results && updates.results.length === 0"
      class="generic-text"
    >
      No Program Updates
    </span>
    <v-card
      class="box-two-fifth mt-4 mr-6 mb-4"
      style="display: inline-block"
      v-for="update in updates.results"
      :key="update.id"
    >
      <status
        class="update-status-bar"
        :statuses="Array(update.status)"
      ></status>
      <v-card-text class="grey--text font-weight-bold text-left">
        {{ update.status_details.title }}
      </v-card-text>
      <v-card-text class="grey--text font-weight-bold text-right mt-n15">
        {{ update.update_date | formatDate }}
      </v-card-text>
      <v-card-text
        class="grey--text text-left font-weight-bold text-caption mt-n6"
      >
        <img
          v-if="update.full_name === 'Bot, GEPI.io'"
          :src="require('/src/assets/images/gepibot.png')"
          id="gepibot"
        />
        <img v-else :src="update.photo_url" /><span>{{
          getName(update.full_name)
        }}</span>
      </v-card-text>
      <v-card-text class="grey--text mt-n4 update-text">
        <!-- Regex to evaluate if update text has markdown to maintain formatting -->
        <vue-showdown :markdown="update.status_details.text"> </vue-showdown>
      </v-card-text>
    </v-card>
  </div>
</template>
<style>
.update-status-bar {
  width: 100%;
  height: 10px;
  margin-left: 0px;
  border-radius: 2px;
  overflow: hidden;
  margin-top: 0px;
}
.update-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
}
img {
  border-radius: 50%;
  vertical-align: middle;
}
.v-card__text.grey--text.mt-n4.update-text * {
  background: inherit;
  font-size: inherit;
  font-family: inherit;
  padding-top: 0px !important;
}
.v-card__text.grey--text.mt-n4.update-text pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
</style>
<script>
import Status from "../components/Status";
import { VueShowdown } from "vue-showdown";
export default {
  props: {
    endPoint: {
      type: String,
      required: false,
      default: "projects/update?",
    },
    ordering: {
      type: String,
      required: false,
      default: "",
    },
    limit: {
      type: String,
      required: false,
      default: "100",
    },
  },
  components: { Status, VueShowdown },
  data() {
    return {
      updates: {},
    };
  },
  created() {
    this.getUpdates();
  },
  computed: {
    updateCount() {
      return this.updates.count;
    },
  },
  watch: {
    updates(newValue) {
      this.$emit("update-dates", {
        updates: this.updates.results,
      });
    },
  },
  methods: {
    getUpdates: function() {
      this.$api
        .get(`${this.endPoint}ordering=${this.ordering}&limit=${this.limit}`)
        .then((response) => {
          this.updates = response.data;
        });
    },
    getName(name) {
      if (name.includes(",")) {
        let fullName = name.split(",");
        name = `${fullName[1]} ${fullName[0]}`;
      }
      return name;
    },
  },
};
</script>
